//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import taskOverview from "./taskOverview.vue";
import taskCalendar from "./taskCalendar.vue";
export default {
  components: {
    Header: () => import("@/components/Header"),
    taskOverview,
    taskCalendar,
  },
  props: {
    teamValue: {
      type: Number,
      default: 0,
    },
    selMem: {
      type: Object,
      default: () => {
        return null;
      },
    },
    startTime: {
      type: Date,
      default: () => {
        return null;
      },
    },
    endTime: {
      type: Date,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      tabList: [
        {
          value: "taskOverview",
          label: "任务概况",
        },
        {
          value: "taskCalendar",
          label: "任务日历",
        },
      ],
      kind: "taskCalendar",
    };
  },
  methods: {
    openDrawer() {
      if (this.kind == "taskOverview") {
        this.$refs.taskOverview.showDrawer();
      } else {
        this.$refs.taskCalendar.showDrawer();
      }
    },
    memListPage() {
      this.$emit("memListPage");
    },
    /**
     * tab视图切换
     */
    tabChange(val) {
      this.kind = val;
      this.$router.replace(
        `/memberTask/${this.teamValue}/${this.selMem.UserId}/${this.kind}`
      );
    },
  },
  mounted() {
    if (this.$route.params.type == "taskOverview") {
      this.kind = "taskOverview";
    } else {
      this.kind = "taskCalendar";
    }
    if (this.$route.params.jId) {
      var jId = Number.parseInt(this.$route.params.jId);
      this.$router.replace(
        `/memberTask/${this.teamValue}/${this.selMem.UserId}/${this.kind}/${jId}`
      );
    } else {
      this.$router.replace(
        `/memberTask/${this.teamValue}/${this.selMem.UserId}/${this.kind}`
      );
    }
  },
};
