//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    TaskListPage: () => import("@/userview/workPlan/taskListPage"),
  },
  props: {
    teamValue: {
      type: Number,
      default: 0,
    },
    selMem: {
      type: Object,
      default: null,
    },
    startTime: {
      type: Date,
      default: () => {
        return null;
      },
    },
    endTime: {
      type: Date,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      drawer: false,
      isFollow: false, //是否为关注的项目
      selPros: [],
      start: null,
      end: null,
      searchText: null,
      loading: false,
      taskdata: [], //页面数据
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      },
      taskNum: {},
    };
  },
  computed: {
    taskDetailPrefix() {
      return `/memberTask/${this.teamValue}/${this.selMem.UserId}/taskOverview`;
    },
  },
  mounted() {
    this.start = this.startTime;
    this.end = this.endTime;
    this.getTaskData();
    // this.getTaskNum();
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    /**
     * 类型切换
     */
    changeType(type) {
      this.pageData.pageInde = 1;
      this.getTaskData(type);
    },
    /**
     * 搜索任务
     */
    handleSearch() {
      this.pageData.pageInde = 1;
      this.getTaskData();
      // this.getTaskNum();
    },
    /**
     * 获取数量
     */
    getTaskNum() {
      const data = {
        end: this.end ? this.end.timeFormat("yyyy-MM-dd") : null,
        isFollow: this.isFollow,
        searchText: this.searchText,
        start: this.start ? this.start.timeFormat("yyyy-MM-dd") : null,
        userIds: [this.selMem.UserId],
      };
      this.$http
        .post("/Work/Project/QuerySeachJobTypeNum.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.taskNum = resp.data;
          }
        });
    },
    /**
     * 获取数据
     */
    getTaskData(type) {
      this.drawer = false;
      this.loading = true;
      const form = {
        teamId: this.teamValue,
        PageIndex: this.pageData.pageIndex,
        PageSize: this.pageData.pageSize,
        start: this.start ? this.start.timeFormat("yyyy-MM-dd") : null,
        end: this.end ? this.end.timeFormat("yyyy-MM-dd") : null,
        userIds: [this.selMem.UserId],
        searchText: this.searchText,
        isFollow: this.isFollow,
        eventeId: null,
        type: type ? type : 1,
      };
      this.$http
        .post("/Task/QuerySeachJob.ashx", form)
        .then((resp) => {
          if (resp.res == 0) {
            this.taskdata = resp.data.Data.map((m) => {
              return {
                CreateTime: m.CreateTime,
                EndTime: m.EndTime,
                EstimatedTime: m.EstimatedTime,
                EventeId: m.EventeId,
                Fir: m.Firs,
                JId: m.id,
                JobStatus: m.Schedule,
                Name: m.name,
                NewestExecuteTime: m.PerformTime,
                OverWorkTime: m.OverWorkTime,
                Process: m.process,
                SpendTime: m.SpendTime,
                StartTime: m.StartTimes,
                UserId: m.Frontman,
                UserName: m.Frontman,
                WorkNum: m.WorkNum,
                Picture: m.Picture,
              };
            });

            this.pageData.totalNum = resp.data.Total;
          }
        })
        .finally(() => (this.loading = false));
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;
      this.getTaskData();
    },
    currentChange(val) {
      this.pageData.pageIndex = val;
      this.getTaskData();
    },
  },
};
