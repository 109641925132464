//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Notification } from "element-ui";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "taskCalendar",
  components: {
    // XHeader: () => import("@/components/Header"),
    // selProject: () => import("@/components/Selectors/selPro"),
    TaskCalendarWidget: () => import("@/components/TaskCalendar"),
  },
  props: {
    teamValue: {
      type: Number,
      default: 0,
    },
    selMem: {
      type: Object,
      default: null,
    },
    startTime: {
      type: Date,
      default: () => {
        return null;
      },
    },
    endTime: {
      type: Date,
      default: () => {
        return null;
      },
    },
  },
  data: () => {
    const now = new Date();
    const start = dayjs(now).subtract(1, "month").toDate();
    return {
      start,
      end: now,
      drawer: false,
      data: [],
      summary: {},
      loading: false,
      desktopStickyTop: "72px",
    };
  },
  created() {
    window.addEventListener("resize", this.calcDesktopStickyTop);
  },
  mounted() {
    this.start = this.startTime;
    this.end = this.endTime;
    this.handleSearch();
    // this.calcDesktopStickyTop();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calcDesktopStickyTop);
  },
  computed: {
    ...mapState(["user"]),
    taskDetailPrefix() {
      return `/memberTask/${this.teamValue}/${this.selMem.UserId}/taskCalendar`;
    },
    isTaskCalendar() {
      const {
        $route: { path },
      } = this;
      return path === "/taskCalendar";
    },
    titles() {
      return {
        title: this.isTaskCalendar ? "任务日历" : "我的任务",
        titleEnglish: this.isTaskCalendar ? "Task Calendar" : "My Task",
      };
    },
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    handleSearch() {
      if (this.loading) return;
      const u = this.selMem || {};
      if (u.UserId === void 0 || u.UserId === null) {
        return Notification.error({
          title: "请选择成员",
        });
      }
      if (this.start === void 0 || this.start === null) {
        return Notification.error({
          title: "请选择任务开始时间",
        });
      }
      if (this.end === void 0 || this.end === null) {
        return Notification.error({
          title: "请选择任务结束时间",
        });
      }
      this.loading = true;
      this.drawer = false;
      this.$http
        .get("/Task/GetTaskCalendar.ashx", {
          params: {
            teamId: this.teamValue,
            usId: u.UserId,
            sTime: this.start,
            eTime: this.end,
          },
        })
        .then((res) => {
          if (res.res === 0) {
            this.data = res.data;
            // this.summary = res.data.Summary;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calcDesktopStickyTop() {
      const { height } = this.$refs.selectConditions.getBoundingClientRect();
      this.desktopStickyTop = `${height}px`;
    },
  },
};
